<template>
  <div class="col">
    <!-- Languages List  -->
    <!-- Large Screens  -->
    <server-table
      :count="$store.getters['language/total']"
      :DataItems="$store.getters['language/languages']"
      @Refresh="Refresh"
      :is_local="true"
      :loading="$store.getters['language/load']"
      class="
        table table-striped table-hover
        dataTable
        dtr-inline
        hidden-sm-only hidden-xs-only
      "
      :title="`${localization('Languages')}`"
    >
      <template #columns>
        <el-table-column prop="id" label="#" width="100"></el-table-column>
        <el-table-column
          prop="title"
          :label="`${localization('Title')}`"
          min-width="150"
        ></el-table-column>
        <!-- <el-table-column prop="tag" label="Tag"></el-table-column> -->
        <el-table-column
          prop="flag"
          :label="`${localization('RTL')}`"
          min-width="150"
        >
          <template slot-scope="scope" min-width="150">
            <el-switch
              @change="switch_Rtl(scope.row.id)"
              :value="scope.row.flag == 1"
              active-text="RTL"
              inactive-text="LTR"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="publish"
          sortable
          :label="`${localization('Published')}`"
          min-width="150"
        >
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.publish == 1 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 1px; padding: 2px 7px"
            >
              {{
                scope.row.publish == 1
                  ? `${localization("Published")}`
                  : `${localization("Unpublished")}`
              }} </l-button
            >&nbsp;
          </template>
        </el-table-column>
        <el-table-column
          prop="removed"
          sortable
          :label="`${localization('State')}`"
          width="120"
        >
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.removed == 0 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 1px; padding: 2px 7px"
            >
              {{
                scope.row.removed == 0
                  ? `${localization("Active")}`
                  : `${localization("Inactive")}`
              }} </l-button
            >&nbsp;
          </template>
        </el-table-column>
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="td-actions">
              <a
                v-tooltip.top-center="`${localization('Edit Language')}`"
                @click="edit(2, scope.row)"
                class="btn btn-warning btn-link btn-xs"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                v-tooltip.top-center="
                  scope.row.removed == 0
                    ? `${localization('Delete Language')}`
                    : `${localization('Active Language')}`
                "
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <!-- LLkkmGdBJ6!Rkf7 -->
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a>
              <button
                v-tooltip.top-center="
                  scope.row.publish == 0
                    ? `${localization('Published')}`
                    : `${localization('Unpublished')}`
                "
                @click="unpublish(scope.row.id, scope.row.publish)"
                :class="
                  scope.row.publish == 1
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.publish == 0" class="fa fa-upload"></i>
                <i v-else class="fa fa-download"></i>
              </button>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <l-button
          type="info"
          class="ml-2 py-3 text-center"
          @click="edit(1, {})"
        >
          {{ localization("Add Language") }}
        </l-button>
      </template>
    </server-table>

    <!-- Medium & Small Screens  -->
    <server-table
      :count="$store.getters['language/total']"
      :DataItems="$store.getters['language/languages']"
      @Refresh="Refresh"
      :is_local="true"
      :loading="$store.getters['language/load']"
      class="
        table table-striped table-hover
        dataTable
        dtr-inline
        hidden-md-only hidden-lg-only hidden-xl-only
      "
      :title="`${localization('Languages')}`"
    >
      <template #columns>
        <el-table-column prop="id" label="#" width="70"></el-table-column>
        <el-table-column prop="title" :label="`${localization('Title')}`">
          <template slot-scope="scope">
            <div
              class="
                d-flex
                flex-column flex-sm-row
                justify-content-sm-between
                align-items-sm-center
              "
            >
              <p>{{ scope.row.title }}</p>
              <el-switch
                @change="switch_Rtl(scope.row.id)"
                :value="scope.row.flag == 1"
                :active-text="`${localization('Active RTL')}`"
                :inactive-text="`${localization('Active LTR')}`"
              >
              </el-switch>
              <br />
              <l-button
                outline
                round
                size="sm"
                :type="scope.row.publish == 1 ? 'success' : 'danger'"
                style="font-size: 11px; margin: 1px; padding: 2px 7px"
              >
                {{
                  scope.row.publish == 1
                    ? `${localization("Published")}`
                    : `${localization("Unpublished")}`
                }}
              </l-button>
              <br />
              <l-button
                outline
                round
                size="sm"
                :type="scope.row.removed == 0 ? 'success' : 'danger'"
                style="font-size: 11px; margin: 1px; padding: 2px 7px"
              >
                {{
                  scope.row.removed == 0
                    ? `${localization("Active")}`
                    : `${localization("Inactive")}`
                }} </l-button
              >&nbsp;
              <div class="d-flex justify-content-between">
                <a
                  v-tooltip.top-center="`${localization('Edit Language')}`"
                  @click="edit(2, scope.row)"
                  class="btn btn-warning btn-link btn-xs"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  v-tooltip.top-center="
                    scope.row.removed == 0 ? 'Delete' : 'Active'
                  "
                  @click="remove(scope.row.id, scope.row.removed)"
                  :class="
                    scope.row.removed == 0
                      ? `btn btn-danger btn-link btn-xs`
                      : `btn btn-success btn-link btn-xs`
                  "
                >
                  <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                  <i v-else class="fa fa-check"></i>
                </a>
                <button
                  v-tooltip.top-center="
                    scope.row.publish == 0
                      ? `${localization('Delete Language')}`
                      : `${localization('Active Language')}`
                  "
                  @click="unpublish(scope.row.id, scope.row.publish)"
                  :class="
                    scope.row.publish == 1
                      ? `btn btn-danger btn-link btn-xs`
                      : `btn btn-success btn-link btn-xs`
                  "
                >
                  <i v-if="scope.row.publish == 0" class="fa fa-upload"></i>
                  <i v-else class="fa fa-download"></i>
                </button>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <l-button type="info" class="ml-2 text-center" @click="edit(1, {})">
          {{ localization("Add Language") }}
        </l-button>
      </template>
    </server-table>

    <!-- Add or Edit Language  -->
    <el-dialog
      :title="
        type == 1
          ? `${localization('Add Language')}`
          : `${localization('Edit Language')}`
      "
      :visible.sync="dialog"
      top="4vh"
      width="70%"
    >
      <el-form
        :rules="rules"
        :model="language"
        style="width: 100%"
        ref="ruleFormAdd"
        label-position="left"
      >
        <el-form-item
          label="Language"
          style="width: 100%"
          class="mr-2"
          prop="title"
        >
          <el-input
            type="text"
            v-model="language.title"
            :placeholder="`${localization('Please enter language')}`"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <!--        <el-form-item label="Tag" label-width="90px" style="width: 100%" class="mr-2">-->
        <!--          <el-input type="text" v-model="language.tag" placeholder="Please enter Tag"-->
        <!--                    style="width: 100%"></el-input>-->
        <!--        </el-form-item>-->
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          w-50
          flex-column flex-lg-row
          d-md-flex
          my-3
          ml-auto
        "
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="primary"
          class="
            text-center
            px-2 px-lg-3
            ml-lg-2
            mx-auto
            d-block
            text-capitalize
            w-100
            my-1
          "
          @click="save"
          >{{ localization("Save") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";

import page from "@tinymce/tinymce-vue";
import Swal from "sweetalert2";

export default {
  name: "index",
  components: { ServerTable },
  data() {
    return {
      dialog: false,
      type: null,
      language: {},
      rules: {
        title: {
          required: true,
          message: "Please this field is required",
          trigger: "blur"
        }
      }
    };
  },

  methods: {
    Refresh(query) {
      this.$store.dispatch("language/list", { query: query });
    },
    edit(type, item) {
      this.language = item;
      this.type = type;
      this.dialog = true;
    },
    remove(id, removed) {
      Swal.fire({
        text:
          removed == 0
            ? `are you sure to delete this Language?`
            : "are you sure to Active this Language?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Confirm!",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("language/delete", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: removed == 0 ? `Deleted` : "Active",
              text:
                removed == 0
                  ? "Language has been deleted."
                  : "Language has been active.",
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false
            });
          });
        }
      });
    },
    unpublish(id, publish) {
      Swal.fire({
        text:
          publish == 1
            ? `are you sure to unpublish this Language?`
            : "are you sure to publish this Language?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Confirm!",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("language/togglePublish", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: publish == 1 ? `Unpublish Language` : "Publish Language",
              text:
                publish == 1
                  ? "Language has been unpublishd."
                  : "Language has been published.",
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false
            });
          });
        }
      });
    },
    closeDailog() {
      this.dialog = false;
      this.Refresh(null);
    },

    save() {
      this.$refs["ruleFormAdd"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("language/save", {
              id: this.type == 1 ? "" : `/${this.language.id}`,
              query: this.language
            })
            .then(_ => {
              this.Refresh(null);
              this.dialog = false;
            });
        } else {
          return false;
        }
      });
    },
    switch_Rtl(id) {
      this.$store.dispatch("language/switch", id).then(_ => {
        this.Refresh(null);
      });
    }
  }
};
</script>

<style scoped>
.container--fluid {
  min-height: 80vh !important;
}
</style>
